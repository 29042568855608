//
// _table-sticky-footer.scss
//

.table-sticky-footer {
    padding: 1px 0 0 0 !important;
}

.table-sticky-footer__checkbox {
    margin-top: 0.8rem;
}