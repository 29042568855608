$kaizan-yellow:                                  #FFB900;
$kaizan-offwhite:                                #FFFCF3;
$kaizan-lightgrey:                               #EBE8E0;
$kaizan-midgrey:                                 #CCCAC3;
$kaizan-darkgrey:                                #61605F;
$kaizan-black:                                   #1D1D1F;


$kaizan-black-100: tint-color($kaizan-black, 80%);
$kaizan-black-200: tint-color($kaizan-black, 60%);
$kaizan-black-300: tint-color($kaizan-black, 40%);
$kaizan-black-400: tint-color($kaizan-black, 20%);
$kaizan-black-500: $kaizan-black;
$kaizan-black-600: shade-color($kaizan-black, 20%);
$kaizan-black-700: shade-color($kaizan-black, 40%);
$kaizan-black-800: shade-color($kaizan-black, 60%);
$kaizan-black-900: shade-color($kaizan-black, 80%);

$kaizan-yellow-100: tint-color($kaizan-yellow, 80%);
$kaizan-yellow-200: tint-color($kaizan-yellow, 60%);
$kaizan-yellow-300: tint-color($kaizan-yellow, 40%);
$kaizan-yellow-400: tint-color($kaizan-yellow, 20%);
$kaizan-yellow-500: $kaizan-yellow;
$kaizan-yellow-600: shade-color($kaizan-yellow, 20%);
$kaizan-yellow-700: shade-color($kaizan-yellow, 40%);
$kaizan-yellow-800: shade-color($kaizan-yellow, 60%);
$kaizan-yellow-900: shade-color($kaizan-yellow, 80%);

$kaizan-darkgrey-100: tint-color($kaizan-darkgrey, 80%);
$kaizan-darkgrey-200: tint-color($kaizan-darkgrey, 60%);
$kaizan-darkgrey-300: tint-color($kaizan-darkgrey, 40%);
$kaizan-darkgrey-400: tint-color($kaizan-darkgrey, 20%);
$kaizan-darkgrey-500: $kaizan-darkgrey;
$kaizan-darkgrey-600: shade-color($kaizan-darkgrey, 20%);
$kaizan-darkgrey-700: shade-color($kaizan-darkgrey, 40%);
$kaizan-darkgrey-800: shade-color($kaizan-darkgrey, 60%);
$kaizan-darkgrey-900: shade-color($kaizan-darkgrey, 80%);
